import * as React from 'react'

export const i18nHe = {
  trustedBy: 'אתם בחברה טובה',
  howDoesSkilledWork: (
    <>
      איך <strong>סקילד</strong> עובד?
    </>
  ),
  // joinSkilledCommunity: `תצטרף לקהילת סקילד`,
  hireDeveloper: `מצא פרילנסר/ית בחינם`,
  applyAsDeveloper: 'תצטרף אלינו',
  applyNow: 'הגש מועמדות',
  israelsFinest: <strong>המפתחים שלנו מומחים ב:</strong>,
  hire: `מצאו פרילנסר/ית`,
  apply: 'הצטרפו אלינו',
  contact: 'צור קשר',
  aboutUs: 'עלינו',
  blog: 'בלוג',
  otherLanguage: 'English',
}
