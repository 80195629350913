import { i18nHe } from './he'

export const clientI18nHe = {
  ...i18nHe,
  tagLine: `אנחנו מוצאים, בוחנים ומראיינים מפתחים פרילנסרים  - בשבילך`,
  subtitle: `"סקילד" היא רשת של מפתחים פרילנסרים, שעברו סינון קפדני על מנת לוודא שאתם עובדים עם האנשים הנכונים. הפרילנסרים שנכנסים לרשת הם בוגרי יחידות טכנולוגיות מובחרות, תורמים לפרויקטי "קוד פתוח" גדולים והינם בוגרי אוניברסיטאות מהמובילות בישראל שעברו על ידינו סינון קפדני.`,
  step1: `ספרו לנו על הצרכים שלכם`,
  step2: `נבדוק את זמינות הפרילנסרים ברשת עד לשני ימי עסקים`,
  step3: `נעזור לכל הצדדים לסגור את החוזה ההוגן ביותר לכולם ויאללה לעבודה`,
  feature1: {
    header: 'נבחרו על ידי מומחים',
    text: `ראיינו מאות מפתחים ועבדנו כפרילנסרים בעצמנו. אנו יודעים מה צריך כדי למצוא את האנשים הנכונים.`,
  },
  feature2: {
    header: 'המוכשרים ביותר',
    text: `כל מפתח "סקילד", עבר בדיקות קפדניות ובדיקות רקע, כדי להבטיח את מקומו איתנו.`,
  },
  feature3: {
    header: 'תמצאו תותח-על מהר',
    text: `יש לנו מאגר מידע של מאות מתכנתים עצמאיים. נמצא לכם משהו תוך שני ימי עסקים.`,
  },
  // feature3: {
  //   header: 'תתחיל בלי סיכון',
  //   text: `אנו מציעים תקופת ניסיון בחינם. אם אינך מרוצה מהתוצאות, אתה לא משלם.`,
  // },
}
