import { graphql } from 'gatsby'
import * as React from 'react'

import LandingPage, { LandingPageProps } from '../components/pages/LandingPage'
import { clientI18nHe } from '../language/clientHe'
import DefaultLayout from '../layouts'

const Index = (props: LandingPageProps) => (
  <DefaultLayout language="he">
    <LandingPage
      i18n={clientI18nHe}
      {...props}
      otherLanguageUrl="/"
      languagePrefix="/he"
      isClient
    />
  </DefaultLayout>
)

export default Index

export const pageQuery = graphql`
  query {
    ...SiteData
  }
`
